export const trimString = function(string) {
    /*
        La méthode trim() permet de retirer les blancs en début et fin de chaîne. 
        Les blancs considérés sont les caractères d'espacement (espace, tabulation, espace insécable, etc.) 
        ainsi que les caractères de fin de ligne (LF, CR, etc.).
    */
    return string.trim();
}

export const sweetAlert = function (swal, toast, position, icon, title, text) {

    /*************************** 
    ** La fonction de sweetalert
    ** Params
    ** swal => this.$swal
    ** toast => Says if sweetalert will be a toast or not (true or false)
    ** position => Position of sweetalert ('top-end', 'center', etc.)
    ** icon => Icon of sweetalert ('error', 'succes', 'warning', 'info')
    ** title => Title of sweetalert
    ** text => Message of sweetalert 
    ***************************/

    return swal({
        // toast: toast,
        // position: 'center',
        showConfirmButton: true,
        // timer: 5000,
        // timerProgressBar: true,
        // didOpen: (toast) => {
        //     toast.addEventListener('mouseenter', swal.stopTimer)
        //     toast.addEventListener('mouseleave', swal.resumeTimer)
        // },
        icon: icon,
        title: title,
        html: "<div style='font-size: 18px; line-height: 1.5;'>"+text+"</div>",
    });
}